<template>
  <v-container class="mb-12 pa-0 pb-8" id="plan-view">
    <v-alert type="info" v-if="info" @input="alert = false" class="ma-4">
      {{ info }}
    </v-alert>
    <v-card v-if="$store.state.Auth.token.claims.type == 'personalizado' && !loading && workedOut == false"
      style="height: calc(100vh - 7em);">

      <v-card-text class="text-center evenly-spaced-content">

        <h2 class="text-h5 mt-8 mb-8">{{ user.displayName }}</h2>
        <h2 class="text-h1 mt-8 mb-8">{{ workoutsCompleted }} <br>
          <h2 class="text-h5 mt-8 mb-8">{{ workoutsCompleted == 1 ? 'Día' : 'Días' }} completados esta semana</h2>
        </h2>

        <v-btn x-large rounded class="mx-4 mb-8" color="success" @click="finishDailyWorkout" :loading="loading2">
          <v-icon left large>
            mdi-play
          </v-icon>

          Comenzar entrenamiento
        </v-btn>
      </v-card-text>
    </v-card>


    <v-skeleton-loader type="image" v-if="loading && !info" class="px-2 py-1 pt-2"></v-skeleton-loader>
    <v-skeleton-loader type="image" v-if="loading && !info" class="px-2 py-1"></v-skeleton-loader>

    <v-skeleton-loader type="image" v-if="loading && !info" class="px-2 py-1"></v-skeleton-loader>



    <v-card v-if="!loading && !info" class="ma-2" v-for="(planElem, iterIndex) in allRoutines" :key="iterIndex + 'rand'"
      :id="planElem.name.replace(/\s/g, '-')" v-show="selected !== null ? selected === iterIndex : true">

      <v-card-title class="pb-0" v-if="iterIndex == selected">
        <v-spacer></v-spacer>
        <v-btn @click="selected = null" text small>
          <v-icon left>
            mdi-chevron-up
          </v-icon>
          Ver menos
        </v-btn>
        <v-spacer></v-spacer>

      </v-card-title>
      <v-card-title id="v-step-0">
        <v-avatar v-if="planElem.color" :color="planElem.color" size="20" class="mr-2"></v-avatar>
        {{ planElem.name }}

        <template v-if="planElem.type.includes('matrix')">
          <v-divider class="mx-2"></v-divider>
          <span class="caption" id="v-step-1">Dia {{ workoutsCompleted }} / Sem {{ planificationWeek || 1 }}</span>
        </template>

        <!--    <template v-if="iterIndex == selected">
                            <v-spacer></v-spacer>

              <v-btn @click="selected = null" fab icon small>
                  <v-icon>
                      mdi-chevron-up
                  </v-icon>
                </v-btn>

              </template> -->


      </v-card-title>
      <v-card-text class="ma-0 pa-0">
        <p id="v-step-3" class="px-4">
          <strong>Descripción:</strong> {{ planElem.description }}
        </p>
        <v-divider></v-divider>



         <v-tabs v-if="selected!=null && planElem.type.includes('matrix')" show-arrows v-model="activeTab"   :slider-color="planElem.color">
            <v-tab v-for="(item, i) in planElem.bloques[0].days.length" :key="'Dia' + i" :disabled="i >= user.plan">
              Día #{{ i + 1 }}

              <span class="caption ml-2" v-if="i == workoutsCompleted - 1 && iterIndex == 0">(HOY)</span>

              <v-chip v-if="i >= user.plan" x-small class="ml-1">
                Aumentar plan
              </v-chip>

            </v-tab>
            <v-tab-item v-for="(dia, index) in user.plan" :key="'Diatab' + index">


              <v-card v-for="(bloque) in planElem.bloques" :key="bloque.title" class="mb-0 mx-1"
                :id="activeTab == index ? 'v-step-bloque-1' : ''">
                <v-divider></v-divider>
                <v-card-title>
                  {{ bloque.title }}

                  <v-spacer></v-spacer>
                  <span class="caption" :id="activeTab == index ? 'v-step-metodo-1' : ''">
                    {{ getMetodo(bloque.days) }}
                  </span>

                  <span :id="activeTab == index ? 'v-step-rpe-1' : ''" class="caption"> <v-avatar size="30" class="ml-1"
                      v-if="bloque.days[planificationWeek - 1] && !isNaN(bloque.days[planificationWeek - 1].rpe)"
                      :color="rpeColor(bloque.days[planificationWeek - 1].rpe)"> {{ getRPE(bloque.days) }}</v-avatar>
                    <span v-else class="caption">
                      RPE {{ getRPE(bloque.days) }}
                    </span>


                  </span>

                </v-card-title>


                <v-divider></v-divider>
            
                <v-card-text v-if="planElem.type=='matrixtext'" class="pt-0"
                  v-html="bloque.days[index] ? bloque.days[index].ejercicioDiario.replace(/\n/g, '<br>') : ''"
                  :id="activeTab == index ? 'v-step-ejercicios-1' : ''">
                </v-card-text>
                <v-card-text v-if="planElem.type=='matrix'" class="pa-0">
                  <v-list   v-if="bloque.days[index] && bloque.days[index].ejercicioDiario">
                    <v-list-item-group color="primary">

                      
                    
                        <ExerciseListItem :id="id" :index="index" :cuant="getCuantificacion(bloque.days)" v-for="(id, index ) in bloque.days[index].ejercicioDiario" :key="id">
                        </ExerciseListItem>

                      
                    </v-list-item-group>
                  </v-list>
                </v-card-text>

              </v-card>




            </v-tab-item>


          </v-tabs>



        
          <Simple  v-if="selected !=null && planElem.type == 'listado'" :exercises="planElem.bloques"  ref="simple" />

          <v-card-text v-if="selected!=null && planElem.type =='text'" v-html="planElem.bloques">


          </v-card-text>



      </v-card-text>

      <v-card-actions v-show="iterIndex != selected">
        <v-spacer></v-spacer>
        <v-btn elevation="0" @click="selectPlan(iterIndex, planElem)">
          <v-icon left>
            mdi-chevron-down
          </v-icon>
          Ver mas
        </v-btn>

      </v-card-actions>
    </v-card>

    <v-tour name="myTour" :steps="steps" :options="myOptions">
      <template slot-scope="tour">
        <v-fade-transition>
          <v-step v-if="tour.steps[tour.currentStep]" :key="tour.currentStep" :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep" :next-step="tour.nextStep" :stop="tour.stop" :skip="tour.skip"
            :is-first="tour.isFirst" :is-last="tour.isLast" :labels="tour.labels">
            <template>
              <div slot="actions">
                <v-btn class="mr-2" small @click="skipTour" v-if="tour.isFirst">Saltar intro</v-btn>

                <v-btn class="mr-2" @click="tour.previousStep" v-if="tour.currentStep > 0">
                  <v-icon>
                    mdi-chevron-left
                  </v-icon>
                </v-btn>
                <v-btn @click="tour.nextStep" color="primary" v-if="!tour.isLast">
                  <v-icon v-if="tour.isLast" left>mdi-check</v-icon>
                  <v-icon v-else left>mdi-chevron-right</v-icon>
                  Siguiente</v-btn>

                <v-btn @click="stopTour" v-if="tour.isLast" color="success">
                  <v-icon left>mdi-check</v-icon>
                  Finalizar</v-btn>
              </div>
            </template>
          </v-step>
        </v-fade-transition>
      </template>
    </v-tour>

  </v-container>
</template>

<script>
import { getFirestore, collection, query, where, getDocs, setDoc, doc, getDoc, addDoc, deleteDoc, updateDoc, runTransaction, Timestamp } from "firebase/firestore";

import moment from 'moment'

import ExerciseListItem from "@/components/planifications/view/ExerciseListItem.vue";
import Simple from "@/components/planifications/view/Simple.vue";


export default {
  components: {
    ExerciseListItem, Simple
  },
  name: 'PlanView',
  data() {
    return {
      selected: null,
      loading2: false,
      activeTab: 0,
      info: null,
      myOptions: {
        useKeyboardNavigation: true,
        labels: {
          buttonSkip: 'Saltear intro',
          buttonPrevious: 'Anterior',
          buttonNext: 'Siguiente',
          buttonStop: 'Finalizar',
        }
      },
      steps: [
        {
          target: '#v-step-0',  // We're using document.querySelector() under the hood

          content: `En esta sección podrás ver tu el dia de tu planificación de entrenamiento en curso. <br><br> <strong>¡Vamos a verla!</strong> `,
          params: {
            placement: 'bottom',
            highlight: true
          },
          before: type => new Promise((resolve, reject) => {

            this.activeTab = this.workoutsCompleted - 1
            this.$nextTick(() => {
              this.$vuetify.goTo(0)
              setTimeout(() => {
                resolve()
              }, 300)
            })

          })
        },
        {
          target: '#v-step-1',
          content: 'Aqui podrás ver el día de la semana y la semana de la planificación que estás siguiendo. <br><br> Para avanzar en la planificación es estrictamente necesario registrar asistencia al concurrir al centro.',
          params: {
            placement: 'bottom',
            highlight: true
          },
          before: type => new Promise((resolve, reject) => {
            this.$nextTick(() => {
              this.$vuetify.goTo(0)
              setTimeout(() => {
                resolve()
              }, 300)
            })

          })
        },
        {
          target: '#v-step-3',
          content: 'Aquí podrás ver la descripción de tu planificación.',
          params: {
            placement: 'bottom',
            highlight: true
          },
          before: type => new Promise((resolve, reject) => {
            this.$nextTick(() => {
              this.$vuetify.goTo(0)
              setTimeout(() => {
                resolve()
              }, 300)
            })

          })
        },
        {
          target: '#v-step-bloque-1',
          content: 'Aquí podrás ver los bloques de tu plan y su descripción.',
          params: {
            placement: 'bottom',
            highlight: true
          },
          before: type => new Promise((resolve, reject) => {
            this.$nextTick(() => {
              this.$vuetify.goTo(0)
              setTimeout(() => {
                resolve()
              }, 300)
            })

          })
        },
        {
          target: '#v-step-metodo-1',
          content: 'Aquí podrás ver el método a utilizar en este bloque.',
          params: {
            placement: 'bottom',
            highlight: true
          },
          before: type => new Promise((resolve, reject) => {
            this.$nextTick(() => {
              this.$vuetify.goTo(0)
              setTimeout(() => {
                resolve()
              }, 300)
            })

          })
        },
        {
          target: '#v-step-ejercicios-1',
          content: 'Aquí podrás ver los ejercicios del bloque.',
          params: {
            placement: 'bottom',
            highlight: true
          },
          before: type => new Promise((resolve, reject) => {
            this.$nextTick(() => {
              this.$vuetify.goTo(0)
              setTimeout(() => {
                resolve()
              }, 300)
            })

          })
        },
        {
          target: '#v-step-cuant-1',
          content: 'Aquí podrás ver la cuantificación del bloque.',
          params: {
            placement: 'bottom',
            highlight: true
          },
          before: type => new Promise((resolve, reject) => {
            this.$nextTick(() => {
              this.$vuetify.goTo(0)
              setTimeout(() => {
                resolve()
              }, 300)
            })

          })
        },
        {
          target: '#v-step-rpe-1',
          content: 'Aquí podrás ver el RPE del bloque.',
          params: {
            placement: 'bottom',
            highlight: true
          },
          before: type => new Promise((resolve, reject) => {
            this.$nextTick(() => {
              this.$vuetify.goTo(0)
              setTimeout(() => {
                resolve()
              }, 300)
            })

          })
        }



      ],
      loading: true,
      user: {},
      workoutDoc: {},
      workoutsCompleted: 0,
      workoutDates: [],
      workoutPlan: {},
      alert: false,
      hideFinish: false,
      showActions: false,
      planificationDoc: false,
      planificationWeek: null,
      emptyWorkoutDocs: false,
      workedOut: false,
      allRoutines: [],


    }
  },
  methods: {
    stopTour() {
      this.$store.commit('SET_PLANIFICATION_INTRO_SEEN', true)
      this.$tours['myTour'].stop()
    },
    skipTour() {
      this.$store.commit('SET_PLANIFICATION_INTRO_SEEN', true)
      this.$tours['myTour'].skip()
    },
    rpeColor(value) {
      value = parseInt(value)
      if (value === 1) return 'green lighten-2';
      else if (value === 2) return 'green lighten-1';
      else if (value === 3) return 'green ';
      else if (value === 4) return 'green darken-1';
      else if (value == 5) return 'green darken-2';
      else if (value === 6) return 'yellow darken-2';
      else if (value === 7) return 'orange';
      else if (value === 8) return 'orange darken-3';
      else if (value === 9) return 'red';
      else if (value === 10) return 'red darken-3';
    },
    async getUser() {
      const db = getFirestore()
      let user = this.$store.state.Auth.token.claims.user_id


      const docRef = doc(db, `users/${user}`);
      const docSnap = await getDoc(docRef);

      let data = docSnap.data()

      data.plan = parseInt(data.plan)

      if (data.plan == 0) {
        const db = getFirestore()
        let scheduleIntervalsRef = doc(db, `configurations/schedule`);
        let scheduleIntervals = await getDoc(scheduleIntervalsRef);
        let scheduleIntervalsData = scheduleIntervals.data()
        let maxDays = 0
        scheduleIntervalsData.Domingo.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Lunes.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Martes.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Miercoles.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Jueves.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Viernes.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Sabado.length > 0 ? maxDays += 1 : null

        data.plan = maxDays
      }

      this.user = data

      if (this.user.endOfSubscription) {
        let endOfSubscription = new Date(this.user.endOfSubscription.seconds * 1000)
        let today = moment().toDate()
        if (endOfSubscription < today) {
          await this.setAlert('user_subscription_expired', today, null)
          this.$router.push({ name: 'suscripcion' })
        } else {
          //calculate diffs and if less than 5 days, show alert set this.alert=true

          let diff = endOfSubscription.getTime() - today.getTime();
          let days = Math.ceil(diff / (1000 * 3600 * 24));
          if (days <= 5) {
            this.alert = days;
          }
        }
      }


    },
    async getPlanificationWeek() {
      const db = getFirestore()
      const user_id = this.$store.state.Auth.token.claims.user_id
      const workoutsRef = collection(db, `users/${user_id}/workouts`);


      if (this.user.planification) {
        const q = query(workoutsRef, where("plan", "==", this.user.planification));

        const workoutDocs = await getDocs(q);
        if (workoutDocs.empty && this.user.type != 'personalizado' && this.user.type != 'online') {
          this.planificationWeek = 1;

        } else {
          this.planificationWeek = workoutDocs.docs.length;
        }
      } else {
        this.info = 'Sin planificación asignada'
      }

    },
    async getWorkoutsCompleted() {

      const db = getFirestore()
      const user_id = this.$store.state.Auth.token.claims.user_id
      const workoutsRef = collection(db, `users/${user_id}/workouts`);
      const startOfWeek = this.getStartOfWeek();
      const endOfWeek = this.getEndOfWeek();

      const q = query(workoutsRef, where("weekStart", ">=", startOfWeek), where("weekStart", "<", endOfWeek));

      const workoutDocs = await getDocs(q);
      if (workoutDocs.empty) {
        this.workoutDoc = await addDoc(workoutsRef, { weekStart: startOfWeek, day: 0, dates: [] })
        this.workoutsCompleted = 0
        this.activeTab = 0
        this.workoutDates = []
        this.$emit('showActions')
      } else {
        this.workoutDoc = workoutDocs.docs[0]
        this.workoutsCompleted = this.workoutDoc.data().day
        this.activeTab = this.workoutDoc.data().day - 1
        this.workoutDates = this.workoutDoc.data().dates


        const formattedDate = moment().format('YYYY-MM-DD HH:mm:ss');

        //check if plan is equal to worked out days and emit and event
        if (this.user.plan == this.workoutsCompleted) {

          this.hideFinish = true;

          this.$emit('hideActions')



        } else {
          if (this.workoutDates.length > 0 && this.workoutDates[this.workoutDates.length - 1].substring(0, 10) == formattedDate.substring(0, 10)) {
            this.hideFinish = true;
            this.workedOut = true
          } else {
            this.$emit('showActions')
          }
        }




      }
    },
    getStartOfWeek() {
      const now = new Date();
      let day = now.getDay();
      const diff = (day === 0 ? -6 : 1); // if it's Sunday, subtract 6, otherwise 1
      const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - day + diff);
      return startOfWeek;
    },
    getEndOfWeek() {
      const startOfWeek = this.getStartOfWeek();
      const endOfWeek = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + 6);
      return endOfWeek;
    },
    async getWorkoutPlan() {
      const db = getFirestore()
      let routines = []
      if (this.user.routines) {
        routines = this.user.routines;
        if (routines.length > 0) {
          this.user.planification = routines[0]

          if (routines.length == 1) {
            this.selected = 0;
          }


        } else {
          this.info = 'Sin planificación asignada. Contacta a tu entrenador.'
          return;
        }

      } else {
        this.info = 'Sin planificación asignada. Contacta a tu entrenador.'
        return;
      }


      routines.forEach(async (routine) => {

        let planificationsRef = doc(db, `planifications/${routine}`);
        let planificationDoc = await getDoc(planificationsRef);
        if (planificationDoc.exists() === false) {
          this.info = 'Planificación no encontrada. Contacta a tu entrenador.'
          return;
        }

        let data = planificationDoc.data()
        if (data.type.includes('matrix')){
          /* // now here it should iterate the bloques, some bloques can have a 'reference' attribute to another block in the planification blockId:0
                planificationId:"01INm6alCTYq9KBf5dmB"
                planificationName:"FITNESS: CORE / HIIT FITNESS"
                title:"CORE"  as an example
                this should get the bloques and iterate them, if the bloque has a reference attribute, it should get the reference block and add it to the bloques array with the same info,
                to work it must get the planificationid reference and after getting its data select the block info with the blockId reference
              */

          let bloques = data.bloques
          let newBloques = []
          bloques.forEach(async (bloque, index) => {
            if (bloque.reference) {
              let referencePlanification = doc(db, `planifications/${bloque.reference.planificationId}`);
              let referencePlanificationDoc = await getDoc(referencePlanification);
              if (referencePlanificationDoc.exists() === false) {
                this.info = 'Planificación no encontrada. Contacta a tu entrenador.'
                return;
              }
              let referenceData = referencePlanificationDoc.data()
              let referenceBloques = referenceData.bloques
              bloques[index] = referenceBloques[bloque.reference.blockId]

            }
            newBloques.push(bloque)
          })
        }

        this.allRoutines.push(data)
      }
      );

      const planificationsRef = doc(db, `planifications/${this.user.planification}`);
      const planificationDoc = await getDoc(planificationsRef);
      if (planificationDoc.exists() === false) {
        this.info = 'Planificación no encontrada. Contacta a tu entrenador.'
        return;
      }

      await this.getPlanificationWeek();


      this.planificationDoc = planificationDoc.data()
    },
    startTour() {
      this.$tours['myTour'].start()

    },
    async finishDailyWorkout() {
      this.loading2 = true;
      let id = this.$store.state.Auth.token.claims.user_id
      this.hideFinish = true;
      this.workingOut = false;
      if (!this.workoutDoc) return;
      const documentID = this.workoutDoc.id;
      // array of workouts
      let dates = this.workoutDates;
      const formattedDate = moment().format('YYYY-MM-DD HH:mm:ss');

      // add the current date to the dates array
      dates.push(formattedDate);

      const obj = {
        day: this.workoutsCompleted + 1,
        dates: dates,
      };

      if (this.user.planification) {
        obj.plan = this.user.planification
      }

      const db = getFirestore();

      const docRef = doc(db, `users/${id}/workouts/${documentID}`);
      updateDoc(docRef, obj);
      this.workoutsCompleted += 1;

      //check if plan is equal to worked out days and emit and event
      if (this.user.plan == this.workoutsCompleted) {
        this.$notify({
          group: 'feedback',
          title: 'Atención',
          text: 'Plan completado!! Continua asi!!',
          type: 'success'
        });
      }



      //await this.getWorkoutPlan();
      this.loading2 = false;
      this.workedOut = true;
      this.info = null;

      this.$notify({
        group: 'feedback',
        title: 'Exito',
        text: 'Entrenamiento registrado',
        type: 'success'
      });

      this.$nextTick(() => {

        if (!this.$store.state.planificationIntroSeen) {
          this.$nextTick(() => {
            if (!this.info)
              this.$tours['myTour'].start()

          })
        }
      })

    },
    selectPlan(iterIndex, planElem) {
      this.selected = iterIndex;
      this.activeTab = 0;
      this.$nextTick(() => {
        this.$vuetify.goTo(0)
      }
      )
    },
    getMetodo(days) {
      let metodo = 'Sin metodo'
      let actualWeek = this.planificationWeek - 1
      let planificationWeek = actualWeek % days.length

      if (days[planificationWeek]) {
        metodo = days[planificationWeek].metodo
      }

      return metodo;
    },
    getCuantificacion(days) {
      let cuant = 'Sin cuantificacion'
      let actualWeek = this.planificationWeek - 1
      let planificationWeek = actualWeek % days.length
      if (days[planificationWeek]) {
        cuant = days[planificationWeek].cuantificacion
      }
      return cuant;
    },
    getRPE(days) {
      let rpe = 'Sin RPE'
      let actualWeek = this.planificationWeek - 1
      let planificationWeek = actualWeek % days.length

      if (days[planificationWeek]) {
        rpe = days[planificationWeek].rpe
      }

      return rpe;

    },
    async setAlert(type, date, description) {
      const db = getFirestore();

      let user_id = this.$store.state.Auth.token.claims.user_id

      try {
        const timestampDate = Timestamp.fromDate(date);

        const newAlert = {
          user_id: user_id,
          type: type,
          date: timestampDate,
          description: description,
          seen: false
        };

        await addDoc(collection(db, 'alerts'), newAlert);

        // now add to the alert user subcollection
        const alertRef = collection(db, `users/${user_id}/alerts`);
        await addDoc(alertRef, newAlert);


      } catch (error) {
        console.error("Error adding document: ", error);
      }
    },


  },
  computed: {
    //
  },
  async mounted() {
    this.$vuetify.goTo(0);

    await this.getUser();
    await this.getWorkoutsCompleted();
    await this.getWorkoutPlan();

    this.loading = false;

    if (!this.$store.state.planificationIntroSeen) {
      this.$nextTick(() => {
        if (!this.info)
          this.$tours['myTour'].start()

      })
    }

  },

}
</script>

<style>
#big .v-skeleton-loader__image {
  height: 90dvh !important;
}

#plan-view .active {
  box-shadow: 0px 0px 8px 3px var(--v-anchor-base) !important;
  padding: 4px !important;
}

.v-step__header {
  background-color: var(--v-anchor-base) !important;
}

.v-step__arrow--dark:before {
  border-color: var(--v-anchor-base) !important;
  background: var(--v-anchor-base) !important;
}

.v-step {
  background: #272727 !important;
  max-width: 288px !important;
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 4px rgb(13 176 237 / 48%) !important;
  -webkit-box-shadow: 0 0 0 4px rgb(13 176 237 / 48%);
}

.evenly-spaced-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* Evenly spaced vertically */
  height: 100%;
  /* Make sure it spans the full height of its parent */
}
</style>


<style scoped></style>